var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    { attrs: { disabled: _vm.disabled, "label-for": _vm.fieldTitle } },
    [
      _c(
        "div",
        {
          staticClass: "schema-form-address-field-container",
          class: { "show-privacy-border": _vm.displayHint },
        },
        [
          _c("field-label", {
            staticClass: "address-title",
            attrs: {
              label: _vm.fieldTitle,
              "help-text": _vm.field.fieldHelpText,
            },
          }),
          _c(
            "div",
            {
              staticClass: "address-fields-and-privacy-level-container",
              class: _vm.privacyProtectedBorder,
            },
            [
              _vm.displayHint
                ? _c(
                    "div",
                    {
                      staticClass: "privacy-levels-container",
                      class: _vm.privacyProtectedBackground,
                    },
                    [
                      _c("div", { staticClass: "privacy-levels" }, [
                        _c("div", { staticClass: "privacy-level-container" }, [
                          _c(
                            "div",
                            { staticClass: "privacy-level-radio" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-form-radio", {
                                    staticClass: "float-left ml-3 mb-0",
                                    attrs: {
                                      value: true,
                                      disabled:
                                        _vm.disabled ||
                                        _vm.showRaSignup ||
                                        _vm.noCompanyAddress,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.toggleHint($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.useHint,
                                      callback: function ($$v) {
                                        _vm.useHint = $$v
                                      },
                                      expression: "useHint",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "privacy-level-icon" },
                            [_c("privacy-protected-icon")],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "privacy-level-text" },
                            [
                              _vm.showRaSignup
                                ? [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "float-left",
                                        attrs: { variant: "link" },
                                        on: { click: _vm.signupForRA },
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "\n                    Use our address.\n                  "
                                          ),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            "\n                    Keep yours private.\n                  "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                : _vm.useCompanyMailingAddress
                                ? [
                                    _c("p", [
                                      _vm._v(
                                        "\n                  Use my company mailing address.\n                "
                                      ),
                                    ]),
                                  ]
                                : [
                                    _c("p", [
                                      _vm._v(
                                        "\n                  Use our address.\n                "
                                      ),
                                    ]),
                                    _c("p", [
                                      _vm._v(
                                        "\n                  Keep yours private.\n                "
                                      ),
                                    ]),
                                  ],
                            ],
                            2
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "privacy-level-container" },
                          [
                            _c(
                              "div",
                              { staticClass: "privacy-level-radio" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-form-radio", {
                                      staticClass: "float-left ml-3 mb-0",
                                      attrs: {
                                        value: false,
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.toggleHint($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.useHint,
                                        callback: function ($$v) {
                                          _vm.useHint = $$v
                                        },
                                        expression: "useHint",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.useCompanyMailingAddress
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "privacy-level-icon" },
                                    [_c("privacy-protected-icon")],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "privacy-level-text" },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "\n                  Use a new shipping address.\n                "
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "\n                  This will not become publicly available.\n                "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "privacy-level-icon" },
                                    [_c("privacy-unprotected-icon")],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "privacy-level-text" },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "\n                  Use my address.\n                "
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "\n                  This may become publicly available.\n                "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "address-fields-container" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            md: _vm.containsAddressField("line2") ? 8 : 12,
                          },
                        },
                        [
                          _c(
                            "validation-wrapper",
                            {
                              attrs: {
                                field: _vm.field,
                                "child-field": "line1",
                              },
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "address-input-field",
                                attrs: {
                                  placeholder: "Line 1",
                                  value: _vm.line1,
                                  disabled: _vm.useHint || _vm.disabled,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.onInput({ line1: $event })
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { xs: "12" } },
                        [
                          _c(
                            "validation-wrapper",
                            {
                              attrs: {
                                field: _vm.field,
                                "child-field": "line2",
                              },
                            },
                            [
                              _vm.containsAddressField("line2")
                                ? _c("b-form-input", {
                                    staticClass: "address-input-field",
                                    attrs: {
                                      placeholder: "Line 2",
                                      value: _vm.line2,
                                      disabled: _vm.useHint || _vm.disabled,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.onInput({ line2: $event })
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            md: _vm.containsAddressField("county") ? 4 : 6,
                          },
                        },
                        [
                          _c(
                            "validation-wrapper",
                            {
                              attrs: {
                                field: _vm.field,
                                "child-field": "city",
                              },
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "address-input-field",
                                attrs: {
                                  placeholder: "City",
                                  value: _vm.city,
                                  disabled: _vm.useHint || _vm.disabled,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.onInput({ city: $event })
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.containsAddressField("county")
                        ? _c(
                            "b-col",
                            { attrs: { xs: "12", md: 4 } },
                            [
                              _c(
                                "validation-wrapper",
                                {
                                  attrs: {
                                    field: _vm.field,
                                    "child-field": "county",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass: "address-input-field",
                                    attrs: {
                                      placeholder: "County",
                                      value: _vm.county,
                                      disabled: _vm.useHint || _vm.disabled,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.onInput({ county: $event })
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            md: _vm.containsAddressField("county") ? 4 : 6,
                          },
                        },
                        [
                          _c(
                            "validation-wrapper",
                            {
                              attrs: {
                                field: _vm.field,
                                "child-field": "state_province_region",
                              },
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "address-input-field",
                                attrs: {
                                  placeholder: "State/Province/Region",
                                  value: _vm.state_province_region,
                                  disabled: _vm.useHint || _vm.disabled,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.onInput({
                                      state_province_region: $event,
                                    })
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _vm.containsAddressField("country")
                        ? _c(
                            "b-col",
                            { attrs: { xs: "12", md: "8" } },
                            [
                              _c(
                                "validation-wrapper",
                                {
                                  attrs: {
                                    field: _vm.field,
                                    "child-field": "country",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    staticClass: "address-input-field",
                                    attrs: {
                                      placeholder: "Country",
                                      value: _vm.country,
                                      options: _vm.countries(),
                                      disabled: _vm.useHint || _vm.disabled,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.onInput({ country: $event })
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.containsAddressField("zip_postal_code")
                        ? _c(
                            "b-col",
                            { attrs: { xs: "12", md: "4" } },
                            [
                              _c(
                                "validation-wrapper",
                                {
                                  attrs: {
                                    field: _vm.field,
                                    "child-field": "zip_postal_code",
                                    rule: [
                                      "US",
                                      "USA",
                                      "United States",
                                    ].includes(_vm.country)
                                      ? "zipcode"
                                      : "",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass: "address-input-field",
                                    attrs: {
                                      placeholder: "Zip/Postal Code",
                                      value: _vm.zip_postal_code,
                                      disabled: _vm.useHint || _vm.disabled,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.onInput({
                                          zip_postal_code: $event,
                                        })
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }